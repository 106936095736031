&.minionslot--is-destroyed {
  .dead-graphic {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    & > span {
      width: 30px !important;
      height: 30px !important;
      position: relative !important;
    }
  }

  &[data-zone-side='1'] .dead-graphic {
    transform: scale(-1);
  }
}
