.component {
  position: absolute;
  top: auto; right: 0; bottom: 0; left: 0;
  z-index: 1;
}

.player__bar {
  // position: absolute;
  // top: auto; right: 0; bottom: 0; left: 0;
  background: gray;
  padding: 0.25rem;
}
