.component {
  align-items: center;
  background: rgba(0, 0, 0, 0.865);
  color: white;
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;

  &.active {
    opacity: 1;
    z-index: 8000;
  }
}
