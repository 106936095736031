.wrapper {
  // --minion-height: 60px;
  // --minion-width: 48px;

  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  height: var(--board-height);
  justify-content: center;
  margin: 0 auto;
  max-height: var(--board-height);
  max-width: var(--board-width);
  min-height: var(--board-height);
  min-width: var(--board-width);
  overflow: visible;
  padding: 0 1em;
  width: var(--board-width);
}
