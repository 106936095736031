.component {
  --card-height: 300px;

  pointer-events: none;
  position: absolute;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: fit-content;
  display: inline-block;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-end;
  will-change: opacity, transform;
  z-index: 1000;
}
