.component {
  $size: #{var(--avatar-size)};

  position: relative;
  width: var(--avatar-size);
  height: var(--avatar-size);
  // top: -25px;
  // left: -25px;
  margin-right: auto;
  margin-left: -1.5rem;
  bottom: -1.5rem;

  & > span {
    border-radius: 50%;
  }

  img {
    object-fit: cover;
  }
}
