html {
  perspective: 100vh;
  position: fixed;
  overflow: hidden;
}

html,
body,
#__next,
#__next > main,
#__next > .bgio-client {
  color: #d1d1d1;
  background: #151515;
  background-color: #151515;
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  overscroll-behavior: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 15px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  user-select: none;
}

a {
  color: #8cc8ff;
}

h1 {
  margin-top: 0;
}
