.card__cost {
  align-items: center;
  bottom: calc(var(--card-height) / -50);
  display: flex;
  flex-flow: column nowrap;
  font-size: var(--card-cost-font-size);
  height: calc(var(--card-height) / 4.714285714);
  justify-content: center;
  left: calc(var(--card-height) / -30);
  position: absolute;
  top: calc(var(--card-height) / -30);
  width: calc(var(--card-height) / 4.714285714);
  z-index: 2;

  & > div {
    z-index: 1;
  }

  &.cost--decreased > div {
    color: #1aff1a;
  }

  &.cost--increased > div {
    color: #ff57e7;
  }
}
