.collection__page {
  --card-height: 145px;
  --card-cost-font-size: 1.25em;
  --card-power-font-size: 1.25em;
  
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 35vh;
  // transition: padding-top 450ms var(--animation-transition-cubic);
  padding-top: 0;

  h1 {
    margin-bottom: 0;
  }
}

.collection__page .page__header {
  @include display-flex(row nowrap, center, space-between);
  margin-bottom: 1rem;
}

.collection__page .grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em;
  justify-items: center;
  width: fit-content;
  margin: 0 auto;
}

.collection__page .grid-item {
  width: fit-content;

  div[data-component="Card"] {
    margin: 0 !important;
  }

  &[data-locked='true'] {
    cursor: not-allowed;
  }
}
