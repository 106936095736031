.wrapper {
  align-items: center;
  border-color: red;
  border-style: dotted;
  border-width: 2px;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  outline: none;
  overflow: hidden;
  // pointer-events: none;
  position: absolute;
  right: 0;
  top: auto;
  touch-action: none;
  transition: 150ms ease-in;
  user-select: none;
  width: 100%;
  will-change: border-style, opacity, pointer-events;
  z-index: -1;

  &.global-spell {
    top: auto;
    height: 23%;
    bottom: 31%;
    width: 95%;
    margin: 0 auto;
  }

  &.active {
    opacity: 0.5;
    // pointer-events: auto;
    z-index: 1;
  }
}

.button {
  align-items: center;
  border: none;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  outline: none;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: auto;
  touch-action: none;
  user-select: none;
  width: 100%;
  will-change: pointer-events;
  z-index: 101;

  &.global-spell {
    top: auto;
    height: 23%;
    bottom: 31%;
    width: 95%;
    margin: 0 auto;
  }

  &.active {
    pointer-events: auto;
  }
}
