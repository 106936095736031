&.minionslot--can-be-destroyed {
  $radius: #{var(--minion-border-radius)};

  box-shadow: var(--box-shadow-can-be-attacked);
  pointer-events: auto;
  transition: box-shadow 600ms ease-in-out;
  will-change: box-shadow;

  & > * {
    pointer-events: none !important;
  }

  &:hover {
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0);
  }

  &:after {
    animation: targeting-ripple-destroy 1200ms linear infinite;
    border-radius: #{$radius};
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 600ms ease-in-out;
    will-change: opacity;
  }

  &:hover:after {
    opacity: 1;
  }

  @keyframes targeting-ripple-destroy {
    0% {
      box-shadow: 0 1px 5px var(--box-shadow-can-be-attacked-color-ripple),
        0 0 0 0 var(--box-shadow-can-be-attacked-color-ripple),
        0 0 0 2.5px var(--box-shadow-can-be-attacked-color-ripple),
        0 0 0 5px var(--box-shadow-can-be-attacked-color-ripple);
    }
    100% {
      box-shadow: 0 1px 5px var(--box-shadow-can-be-attacked-color-ripple),
        0 0 0 2.5px var(--box-shadow-can-be-attacked-color-ripple),
        0 0 0 5px var(--box-shadow-can-be-attacked-color-ripple),
        0 0 0 20px rgba(0, 0, 0, 0);
    }
  }
}
