.component {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-size: 0.75rem;
  border-radius: 0;
  border: 0;
  height: auto;
  min-width: 105px;
  background-color: yellow;
  padding: 0 0.25rem;

  &[disabled] {
    background-color: darkgray;
  }

  & > span {
    line-height: 2.875;
  }
}
