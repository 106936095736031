$bs-color1: rgba(0, 0, 0, 0.925);
$bs-color2: rgba(0, 0, 0, 0.465);
$bs-default: 0 0 1.5px $bs-color1, 0 0 10px $bs-color2;
$bs-default-hover: 0 0 4px $bs-color1, 0 0 35px $bs-color2;

.minion {
  animation-duration: 200ms;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
  background: none;
  border-radius: var(--minion-border-radius);
  box-sizing: border-box;
  font-family: var(--font-family-card);
  font-size: 20px;
  font-size: calc(var(--minion-height) / 8);
  height: var(--minion-height);
  pointer-events: none;
  position: relative;
  user-select: none;
  width: calc(var(--minion-height) / 1.25);
  will-change: transform;
  z-index: 2;

  * {
    user-select: none;
    outline: none;
  }

  &:not(:hover) > article {
    display: none;
  }

  &.minion--is-attacking,
  [class*='target__slot-'] &,
  [class*='target__other_player'] & {
    transform: scale(1.15);
    transition: transform 400ms var(--animation-transition-cubic);
  }
}

// prettier-ignore
.minion.minion--has-bubble {
  & > div:nth-child(2) { // attack badge
    transform: translateX(14px);
    transition: transform 200ms ease-out;
  }
  
  & > div:nth-child(3) { // health badge
    transform: translateX(-12px);
    transition: transform 200ms ease-out;
  }
}

.minion.minion--was-attacked {
  animation-name: uk-shake-anim;
  animation-duration: 500ms;
  animation-timing-function: ease-out;
  animation-fill-mode: both;

  @keyframes uk-shake-anim {
    0%,
    100% {
      transform: translateX(0);
    }

    10% {
      transform: translateX(-9px);
    }

    20% {
      transform: translateX(8px);
    }

    30% {
      transform: translateX(-7px);
    }

    40% {
      transform: translateX(6px);
    }

    50% {
      transform: translateX(-5px);
    }

    60% {
      transform: translateX(4px);
    }

    70% {
      transform: translateX(-3px);
    }

    80% {
      transform: translateX(2px);
    }

    90% {
      transform: translateX(-1px);
    }
  }
}

.minion.minion--is-damaged .text__value {
  color: #ff4800;
}

// .minion.minion--is-dead {
//   opacity: 0;
//   transform: scale(0.1);
//   transition: 600ms cubic-bezier(0.19, 1, 0.22, 1);
// }

// prettier-ignore
.minion {
  &.minion__race--location,
  &.minion__race--location > div:nth-child(1), // image wrapper
  &.minion__race--location > div:nth-child(1) {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  &.minion__race--location {
    // attack badge
    & > div:nth-child(2) {
      left: -10px;
      bottom: -2px;
      & > img { filter: grayscale(1); }
    }

    // health badge
    & > div:nth-child(3) { right: -10px; bottom: -2px; }
  }
}

.loader {
  height: 100%;
  width: 100%;
  // @include animation-swipe-card-image;
}
