.site__footer {
  @include position-absolute(auto, 0, 0, 0, 100);
}

.footer__nav {
  ul {
    @include display-flex(row nowrap, flex-end, space-between);
    background-color: rgb(143 18 18);
    list-style-type: none;
    width: 100vw;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
  }

  a {
    @include display-flex(column nowrap, center, flex-end);
    color: white;
    background-color: var(--color-rarity-MYTHIC);
    white-space: nowrap;
    line-height: 1;
    font-family: var(--font-family-card);
    font-size: 0.625em;
    text-transform: uppercase;
    text-decoration: none;
    padding: 1em 2em;
  }

  a[href="/play"] {
    padding-left: 3.5em;
    padding-right: 3.5em;
  }

  a span {
    display: block;
    width: 100%;
    text-align: center;
  }

  a .item__text {
    margin-top: 0.5em;
    white-space: nowrap;
  }

  a svg {
    height: 20px;
    max-height: 20px;
    width: auto;
  }
}
