.component {
  border-radius: calc(var(--card-height) / 28.571428571);
  display: block;
  height: var(--card-height);
  image-rendering: pixelated;
  position: relative;
  z-index: 1;
}

.loader {
  background-color: #111;
  border-radius: calc(var(--card-height) / 28.571428571);
  height: var(--card-height);
  image-rendering: pixelated;
  position: relative;
  z-index: 1;
}