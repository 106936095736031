
.wrapper {
  $useFullBorder: true;
  $borderRadius: #{var(--minion-border-radius)};

  align-items: center;
  background-color: #191919;
  border-bottom-left-radius: var(--minion-border-radius);
  border-bottom-right-radius: var(--minion-border-radius);
  border-top-left-radius: var(--minion-border-radius);
  border-top-right-radius: var(--minion-border-radius);
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: flex-start;
  object-fit: cover;
  overflow: hidden;
  position: relative;
  z-index: 1;

  .fpo {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0; z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 2em;
    opacity: 0.75;
  }

  @if $useFullBorder {
    border: 1px solid transparent;
    will-change: border-color;

    &,
    &.FREE {
      border-top-color: #fff;
      border-left-color: rgb(214, 209, 209);
      border-right-color: rgb(214, 209, 209);
      border-bottom-color: #777;
    }

    &,
    &.COMMON {
      border-top-color: #b0ffb3;
      border-left-color: var(--color-rarity-COMMON);
      border-right-color: var(--color-rarity-COMMON);
      border-bottom-color: rgb(99, 229, 103);
    }
    
    &.SUPERIOR {
      border-top-color: #c7edff;
      border-left-color: var(--color-rarity-SUPERIOR);
      border-right-color: var(--color-rarity-SUPERIOR);
      border-bottom-color: #2885b1;
    }
    
    &.REMARKABLE {
      border-top-color: #c8a0ff;
      border-left-color: var(--color-rarity-REMARKABLE);
      border-right-color: var(--color-rarity-REMARKABLE);
      border-bottom-color: #7f37e4;
    }
    
    &.MYTHIC {
      border-top-color: #ffb8a5;
      border-left-color: var(--color-rarity-MYTHIC);
      border-right-color: var(--color-rarity-MYTHIC);
      border-bottom-color: #af3d1f;
    }
    
    &.GOLDEN {
      border-top-color: #fff897;
      border-left-color: var(--color-rarity-GOLDEN);
      border-right-color: var(--color-rarity-GOLDEN);
      border-bottom-color: #ccbf10;
    }
  } @else {
    border-top: 1px solid rgba(255, 255, 255, 0.65);
    border-bottom: 1px solid rgba(0, 0, 0, 0.875);
  }

  &:before {
    background: black;
    border-radius: $borderRadius;
    bottom: 0;
    // box-shadow: inset 0px 0px 0px 2px rgba(190, 190, 190, 0.265);
    content: '';
    height: 100%;
    left: 0;
    pointer-events: none;
    touch-action: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    opacity: 0;
    transition: opacity 200ms ease-in;
    will-change: opacity;
  }

  &.is-destroyed:before {
    opacity: 0.45;
  }
}

.image {
  animation-duration: 250ms;
  animation-fill-mode: both;
  animation-name: minion-img-fade;
  animation-timing-function: linear;
  height: 100%;
  image-rendering: pixelated;
  object-fit: cover;
  pointer-events: none;
  width: 100%;

  // prettier-ignore
  @keyframes minion-img-fade {
    0%    { opacity: 0; }
    100%  { opacity: 1; }
  }
}

.loader {
  height: 100%;
  width: 100%;
  // @include animation-swipe-card-image;
}
