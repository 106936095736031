&.minionslot--event-was-triggered {
  $radius: #{var(--minion-border-radius)};
  $animDur: 1200;
  $delayInc: 200;

  pointer-events: none;
  touch-action: none;
  position: absolute;
  top: 0; right: 0; left: 0; bottom: 0;
  width: 100%;
  height: 100%;
  
  animation: event-ripple #{$animDur}ms linear;
  border-radius: var(--minion-border-radius);
  opacity: 1;
  transition: opacity 600ms ease-in-out;
  will-change: opacity;

  // &[data-index='0']:after { animation-delay: 200ms; }
  // &[data-index='1']:after { animation-delay: 400ms; }
  // &[data-index='2']:after { animation-delay: 600ms; }
  // &[data-index='3']:after { animation-delay: 800ms; }
  // &[data-index='4']:after { animation-delay: 1000ms; }
  // &[data-index='5']:after { animation-delay: 1200ms; }
  // &[data-index='6']:after { animation-delay: 1400ms; }

  @keyframes event-ripple {
    0% {
      opacity: 0;
      box-shadow: 0 0 0 var(--box-shadow-is-selected-color-ripple),
        0 0 0 0 var(--box-shadow-is-selected-color-ripple),
        0 0 0 0 var(--box-shadow-is-selected-color-ripple),
        0 0 0 0 var(--box-shadow-is-selected-color-ripple);
    }
    5% {
      opacity: 1;
    }
    80% {
      box-shadow: 0 1px 5px var(--box-shadow-is-selected-color-ripple),
        0 0 0 0 var(--box-shadow-is-selected-color-ripple),
        0 0 0 5px var(--box-shadow-is-selected-color-ripple),
        0 0 0 20px rgba(0, 0, 0, 0);
    }
    100% {
      opacity: 0;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0),
        0 0 0 0 rgba(0, 0, 0, 0),
        0 0 0 0 rgba(0, 0, 0, 0),
        0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
}
