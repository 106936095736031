.component {
  position: relative;
  z-index: 2;
}

.zone-name {
  font-size: 9px;
  // font-style: italic;
  letter-spacing: -0.25px;
  color: white;

  // prettier-ignore
  text-shadow: 0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black;
}

.zone-power-text {
  margin-top: 0.25em;
  font-size: 11px;
  font-weight: bold;
  line-height: 0.875;
  color: white;

  // prettier-ignore
  text-shadow: 0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black;
}
