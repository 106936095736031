.component {
  background-color: #191919;
  // border-radius: var(--card-border-radius);
  height: calc(var(--card-height) / 1.739130435);
  margin-bottom: 0;
  margin-left: calc(var(--card-height) / 20);
  margin-right: calc(var(--card-height) / 22.222222222);
  margin-top: calc(var(--card-height) / 8.7111111111);
  overflow: hidden;
  position: absolute;
  width: calc(var(--card-height) / 1.628293223);
  z-index: 0;

  .fpo {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0; z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 2em;
    opacity: 0.75;
  }
}

.image {
  // animation: img-fade 500ms linear forwards;
  image-rendering: pixelated;
  object-fit: cover;
  position: relative;
  width: 100%;

  // prettier-ignore
  @keyframes img-fade {
    0%   { opacity: 0; }
    100% { opacity: 1; }
  }
}

.loader {
  animation: placeHolderSwipe 2s infinite linear forwards;
  background: #191919;
  background-repeat: no-repeat;
  background-image: linear-gradient(
    to right,
    #191919 0%,
    #202020 20%,
    #191919 40%,
    #191919 100%
  );
  height: 100%;
  width: 100%;

  // prettier-ignore
  @keyframes placeHolderSwipe {
    0%   { background-position: calc(-#{var(--card-height)} + 200px) 0; }
    100% { background-position: calc(var(--card-height) + 200px) 0; }
  }
}
