// https://codepen.io/hicTech/pen/KmKrjb
._scrollable {
  $size: 15px;
  $little_version_ratio: 2;
  $scrollbar-bg-color: rgba(255, 255, 255, 0.15);
  $scrollbar-handler-color: rgba(255, 255, 255, 0.15);
  $scrollbar-handler-color-hover: rgba(255, 255, 255, 0.3);
  $scrollbar-coner-color: rgba(255, 255, 255, 0);

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    background: none;
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-track {
    background-color: $scrollbar-bg-color;
    border-radius: $size;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $size;
    background-color: $scrollbar-handler-color;
    border: solid 1px rgba(0, 0, 0, 0.3);

    &:hover {
      background-color: $scrollbar-handler-color-hover;
    }
  }

  &::-webkit-scrollbar-corner {
    background-color: $scrollbar-coner-color;
  }

  &.little {
    &::-webkit-scrollbar {
      background: none;
      width: $size / $little_version_ratio;
      height: $size / $little_version_ratio;
    }

    &::-webkit-scrollbar-track {
      border-radius: $size / $little_version_ratio;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: $size / $little_version_ratio;
    }
  }

  &.autoHideScrollbar {
    overflow-y: hidden;

    &:hover {
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;

      &.only-y {
        overflow-x: hidden !important;
      }

      &.only-x {
        overflow-y: hidden !important;
      }
    }
  }

  &.only-y:not(.autoHideScrollbar) {
    overflow-y: scroll !important;
  }

  &.only-x:not(.autoHideScrollbar) {
    overflow-y: hidden !important;
  }
}
