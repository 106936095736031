.card {
  $borderRadius: #{calc(var(--card-height) / 28.571428571)};

  border-radius: $borderRadius;
  color: white;
  font-family: var(--font-family-card);
  height: var(--card-height);
  width: calc(var(--card-height) / var(--card-width-division));
  margin: 0 auto;
  position: relative;
  pointer-events: none;
  touch-action: none;

  *,
  & * {
    user-select: none;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.65);
    z-index: 3;
    pointer-events: none;
    touch-action: none;
    transition: 200ms ease-in-out;
    transition-property: opacity;
    opacity: 0;
    border-radius: $borderRadius;
  }

  [data-component='CardCost'],
  [data-component='CardHealth'],
  [data-component='CardPower'],
  [data-component='CardType'],
  [data-component='CardSubType'] {
    transition: 150ms transform var(--animation-transition-cubic);
  }

  &.cant-play {
    // color: #626262;

    p {
      // color: #626262;
    }

    &:before {
      opacity: 1;
    }

    [data-component='CardCost'] {
      transform: translate(2px, 2px) scale(0.675);
    }

    [data-component='CardHealth'] {
      transform: translate(-1px, 0px) scale(0.675);
    }

    [data-component='CardPower'] {
      transform: translate(-1px, 0px) scale(0.675);
    }

    [data-component='CardType'] {
      transform: translateX(4px);
    }

    [data-component='CardSubType'] {
      transform: translateX(-4px);
    }
  }
}

.card {
  &.card--type-ITEM,
  &.card--type-MINION,
  &.card--type-SPELL,
  &.card--type-WEAPON {
    box-sizing: border-box;
  }
}

.card.dev {
  --card-height: 400px;
}
