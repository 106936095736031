.site__nav {
  @include position-fixed(0, 0, 0, 0, 900);
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: rgba(0,0,0,0.95);
  display: none;

  .active & {
    display: block;
  }
}

.site__nav ul {
  @include display-flex(column nowrap, center, center);
  list-style-type: none;
  // width: fit-content;
  // padding: 0 1em;
  margin: 0 0 auto;
  padding: 0;
  width: 100%;
  height: 85%;
  
  a {
    @include display-flex(row nowrap, center, center);
    color: white;
    height: 65px;
    min-height: 65px;
    max-height: 65px;
    white-space: nowrap;
    line-height: 1;
    font-family: var(--font-family-card);
    text-decoration: none;
  }
}
