.card__text {
  align-items: center;
  bottom: 0;
  color: white;
  display: flex;
  flex-flow: column nowrap;
  font-size: calc(var(--card-height) / 26.666666667);
  height: calc(var(--card-height) / 3.076923077);
  justify-content: center;
  left: 0;
  letter-spacing: calc(var(--card-height) / 833.333333333);
  padding-bottom: calc(var(--card-height) / 11.111111111);
  padding-left: calc(var(--card-height) / 8.695652174);
  padding-right: calc(var(--card-height) / 8.695652174);
  padding-top: calc(var(--card-height) / 20);
  position: absolute;
  text-align: center;
  text-transform: none;
  width: 100%;
  word-spacing: calc(var(--card-height) / 200);
  z-index: 2;

  & > p {
    line-height: 1.15;
    margin: 0;
  }

  strong {
    color: yellow;
  }

  span {
    white-space: nowrap;
  }

  small {
    display: inline-block;
    line-height: 1.1;
  }

  svg {
    position: relative;
    width: inherit;
  }
}