/**
 * forked via getuikit.com/docs/animation
 */

[class*='bccg-animation-'] {
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}

.bccg-animation-delay {
  animation-delay: 400ms !important;
}

.bccg-animation-fade {
  animation-name: bccg-fade;
  animation-duration: 0.8s;
  animation-timing-function: linear;
}

.bccg-animation-scale-up {
  animation-name: bccg-fade-scale-02;
}

.bccg-animation-scale-down {
  animation-name: bccg-fade-scale-18;
}

.bccg-animation-slide-top {
  animation-name: bccg-fade-top;
}

.bccg-animation-slide-bottom {
  animation-name: bccg-fade-bottom;
}

.bccg-animation-slide-left {
  animation-name: bccg-fade-left;
}

.bccg-animation-slide-right {
  animation-name: bccg-fade-right;
}

.bccg-animation-slide-top-small {
  animation-name: bccg-fade-top-small;
}

.bccg-animation-slide-bottom-small {
  animation-name: bccg-fade-bottom-small;
}

.bccg-animation-slide-left-small {
  animation-name: bccg-fade-left-small;
}

.bccg-animation-slide-right-small {
  animation-name: bccg-fade-right-small;
}

.bccg-animation-slide-top-medium {
  animation-name: bccg-fade-top-medium;
}

.bccg-animation-slide-bottom-medium {
  animation-name: bccg-fade-bottom-medium;
}

.bccg-animation-slide-left-medium {
  animation-name: bccg-fade-left-medium;
}

.bccg-animation-slide-right-medium {
  animation-name: bccg-fade-right-medium;
}

.bccg-animation-kenburns {
  animation-name: bccg-scale-kenburns;
  animation-duration: 15s;
}

.bccg-animation-shake {
  animation-name: bccg-shake;
}

.bccg-animation-stroke {
  animation-name: bccg-stroke;
  stroke-dasharray: var(--bccg-animation-stroke);
  animation-duration: 2s;
}

.bccg-animation-reverse {
  animation-direction: reverse;
  animation-timing-function: ease-in;
}

.bccg-animation-fast {
  animation-duration: 0.1s;
}

.bccg-animation-toggle:not(:hover):not(:focus) [class*='bccg-animation-'] {
  animation-name: none;
}

.bccg-animation-toggle {
  -webkit-tap-highlight-color: transparent;
}

.bccg-animation-toggle:focus {
  outline: none;
}

.bccg-spinner {
  animation: bccg-spinner-rotate 1.4s linear infinite;
  margin: 0 auto;
  height: 30px;
  width: 30px;

  & > div {
    stroke-dasharray: 88px;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: bccg-spinner-dash 1.4s ease-in-out infinite;
    stroke-width: 1;
    stroke-linecap: round;
    height: 30px;
    width: 30px;
  }

  &.bccg-spinner-\@2x,
  &.bccg-spinner-\@2x > div {
    height: 90px;
    width: 90px;
  }
}

.bccg-transform-center {
  transform: translate(-50%, -50%);
}

.bccg-transform-origin-top-left {
  transform-origin: 0 0;
}

.bccg-transform-origin-top-center {
  transform-origin: 50% 0;
}

.bccg-transform-origin-top-right {
  transform-origin: 100% 0;
}

.bccg-transform-origin-center-left {
  transform-origin: 0 50%;
}

.bccg-transform-origin-center-right {
  transform-origin: 100% 50%;
}

.bccg-transform-origin-bottom-left {
  transform-origin: 0 100%;
}

.bccg-transform-origin-bottom-center {
  transform-origin: 50% 100%;
}

.bccg-transform-origin-bottom-right {
  transform-origin: 100% 100%;
}

@keyframes bccg-fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bccg-fade-top {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bccg-fade-bottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bccg-fade-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes bccg-fade-right {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes bccg-fade-top-small {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bccg-fade-bottom-small {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bccg-fade-left-small {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes bccg-fade-right-small {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes bccg-fade-top-medium {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bccg-fade-bottom-medium {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bccg-fade-left-medium {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes bccg-fade-right-medium {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes bccg-fade-scale-02 {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes bccg-fade-scale-18 {
  0% {
    opacity: 0;
    transform: scale(1.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes bccg-scale-kenburns {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

@keyframes bccg-shake {
  0%,
  100% {
    transform: translateX(0);
  }

  10% {
    transform: translateX(-9px);
  }

  20% {
    transform: translateX(8px);
  }

  30% {
    transform: translateX(-7px);
  }

  40% {
    transform: translateX(6px);
  }

  50% {
    transform: translateX(-5px);
  }

  60% {
    transform: translateX(4px);
  }

  70% {
    transform: translateX(-3px);
  }

  80% {
    transform: translateX(2px);
  }

  90% {
    transform: translateX(-1px);
  }
}

@keyframes bccg-stroke {
  0% {
    stroke-dashoffset: var(--bccg-animation-stroke);
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes bccg-spinner-rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

@keyframes bccg-spinner-dash {
  0% {
    stroke-dashoffset: 88px;
  }

  50% {
    stroke-dashoffset: 22px;
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 88px;
    transform: rotate(450deg);
  }
}
