.component {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-width: 2px;
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  border-color: inherit;
  border-top-color: inherit;
  border-bottom-color: inherit;
  border-right-color: inherit;
  border-left-color: inherit;
  border-radius: var(--zone-border-radius);
  opacity: 1;
  transition: 200ms ease-in-out;
  transform: scale(1.05925);
  pointer-events: none;

  &.leader-opponent {
    border-top-color: yellow;
    border-bottom-color: inherit;
    // transform: rotate(180deg) scale(1.05925);
  }

  &.leader-player {
    border-bottom-color: yellow;
    border-top-color: inherit;
    // transform: rotate(0deg) scale(1.05925);
  }
}