.text__value {
  color: inherit;
  display: block;
  font-family: var(--font-family-card);
  line-height: 1;
  position: relative;
  
  // prettier-ignore
  &--shadow {
    // color: white;
    text-shadow: 0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black;
  }
}
