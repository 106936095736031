.card__power {
  align-items: center;
  bottom: calc(var(--card-height) / -30);
  display: flex;
  flex-flow: column nowrap;
  font-size: var(--card-power-font-size);
  height: calc(var(--card-height) / 4.714285714);
  justify-content: center;
  position: absolute;
  right: calc(var(--card-height) / -30);
  top: auto;
  transform: translate(2px, 4px) scale(0.875);
  width: calc(var(--card-height) / 4.714285714);
  z-index: 2;

  & > div {
    z-index: 1;
  }

  &__elite {
    right: calc(var(--card-height) / -56.666666667);
    height: calc(var(--card-height) / 4.896551724);
    width: calc(var(--card-height) / 6.639004149);
  }

  &--buffed {
    color: #1aff1a;
  }

  &--debuffed {
    color: #ff5555;
  }
}