.player__handslot {
  pointer-events: none;
  touch-action: none;
  
  @import 'card.discard';
}

.minionslot {
  border-radius: var(--minion-border-radius);
  pointer-events: none;
  touch-action: none;
  position: relative;
  font-size: calc(var(--minion-height) / 8);
  
  @import 'minion.can-be-attacked';
  @import 'minion.can-be-buffed';
  @import 'minion.can-be-debuffed';
  @import 'minion.can-be-destroyed';
  @import 'minion.can-be-healed';
  @import 'minion.event-was-triggered';
  @import 'minion.is-destroyed';
  @import 'minion.onplay-was-triggered';
}
